<template>
    <header class="mainHeader" :class="{'mainHeader--loggedIn' : hasUserProfileLoaded}">

        <!--Guest nav-->
        <div v-if="!hasUserProfileLoaded"
             class="mainHeader__container container">
            <div class="mainHeader__leftSection">
                <button
                    class="mainHeader__hamburger text-center"
                    @click="toggleHamburger"
                    :class="{'mainHeader__hamburger--active' : showHamburger}">
                    <img v-if="!showHamburger" src="~@/assets/images/icons/hamburger.svg?data" alt="open menu">
                    <img v-else style="padding:4px;" src="~@/assets/images/icons/close.svg?data" alt="close">
                </button>
            </div>

            <router-link :to="redirectLink" class="text-center">
                <img
                    @click="closeAllMenus"
                    class="mainHeader__logo"
                    src="~@/assets/images/linquito-logo.svg?data"
                    alt="Linquito">
            </router-link>

            <div class="mainHeader__rightSection">
                <div @click="toggleInterfaceMenu">
                    <img v-if="!showInterfaceMenu"
                         class="mainHeader__flagIcon cursor-pointer"
                         :src="interfaceFlag"
                         :alt="interfaceIso">
                    <img v-else class="mainHeader__flagIcon cursor-pointer"
                         src="~@/assets/images/icons/close.svg?data"
                         alt="close">
                </div>
                <div class="mainHeader__authActions">
                    <button
                        v-if="hasUserProfileLoaded"
                        v-html="$t('CORE.LOGOUT').replace(' ', '<br/>')"
                        @click="logout"/>
                    <button
                        v-else
                        v-html="$t('CORE.LOGIN').replace(' ', '<br/>')"
                        @click="$router.push({name:'auth.login'})"/>
                </div>
            </div>
        </div>

        <!--Logged in nav-->
        <div v-else class="mainHeader__container container">
            <div class="mainHeader__leftSection">
                <router-link :to="redirectLink" class="text-center">
                    <img
                        @click="closeAllMenus"
                        class="mainHeader__logo"
                        src="~@/assets/images/linquito-logo.svg?data"
                        alt="Linquito">
                </router-link>
            </div>

            <div class="mainHeader__centerNav">
                <router-link class="mainHeader__centerNavLink" :to="{ name: 'app.progress'}">
                    <span>{{ $t('CORE.PROGRESS') }}</span>
                </router-link>
                <router-link class="mainHeader__centerNavLink" :to="{ name: 'app.dashboard'}">
                    <span>{{ $t('CORE.LEARN') }}</span>
                </router-link>
            </div>

            <div class="mainHeader__rightSection">
                <button
                    class="mainHeader__hamburger text-center"
                    @click="toggleHamburger"
                    :class="{'mainHeader__hamburger--active' : showHamburger}">
                    <img v-if="!showHamburger" src="~@/assets/images/icons/hamburger.svg?data" alt="open menu">
                    <img v-else style="padding:4px;" src="~@/assets/images/icons/close.svg?data" alt="close">
                </button>
            </div>
        </div>
        <hamburger-sub-menu
            @close="showHamburger = false"
            :user-is-logged-in="hasUserProfileLoaded"
            :is-visible="showHamburger"
            v-show="showHamburger"/>
        <interface-language-selector-sub-menu
            @close="showInterfaceMenu = false"
            v-if="showInterfaceMenu"/>
    </header>
</template>

<script>
    import {mapGetters} from 'vuex';
    import {LANGUAGES} from '@/resources/Countries';
    import AuthService from '@/services/Auth/AuthService';
    import HamburgerSubMenu from '@/components/structure/HamburgerSubMenu';
    import InterfaceLanguageSelectorSubMenu from '@/components/structure/InterfaceLanguageSelectorSubMenu';

    export default {
        name      : 'Header',
        data      : function () {
            return {
                showHamburger    : false,
                showInterfaceMenu: false,
            };
        },
        methods   : {
            logout             : function () {
                AuthService.logoutUser()
                    .then(() => {
                        this.$router.push({name: 'auth.login'});
                    });
            },
            toggleHamburger    : function () {
                this.showInterfaceMenu = false;
                this.showHamburger     = !this.showHamburger;
            },
            toggleInterfaceMenu: function () {
                this.showHamburger     = false;
                this.showInterfaceMenu = !this.showInterfaceMenu;
            },
            closeAllMenus      : function () {
                this.showHamburger     = false;
                this.showInterfaceMenu = false;
            },
        },
        computed  : {
            ...mapGetters({
                interfaceIso        : 'Account/getInterfaceIso',
                hasUserProfileLoaded: 'Account/getUserProfileLoaded',
            }),
            redirectLink : function () {
                const link = this.hasUserProfileLoaded ? 'app.dashboard' : 'home';

                return {name: link};
            },
            interfaceFlag: function () {
                return LANGUAGES.find(lan => lan.iso === this.interfaceIso).flag_icon;
            },
        },
        components: {
            HamburgerSubMenu,
            InterfaceLanguageSelectorSubMenu,
        },
    };
</script>
