<template>
    <div class="interfaceLanguageSelector">
        <div class="interfaceLanguageSelector__container">
            <div class="container max-w-md mx-auto px-2">
                <p class="interfaceLanguageSelector__title">
                    What language do you speak?
                </p>
                <div class="interfaceLanguageSelector__selection">
                    <div v-for="language in interfaceLanguages"
                         @click="selectLanguage(language.value)"
                         :class="getAdditionalItemClass(language.iso)"
                         class="interfaceLanguageSelector__item">
                        <img class="interfaceLanguageSelector__flag"
                             :src="language.img"
                             :alt="language.title">
                        <p class="interfaceLanguageSelector__itemTitle" v-text="language.localised"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';

    export default {
        name     : 'InterfaceLanguageSelectorSubMenu',
        data     : function () {
            return {
                onEscape: e => {
                    if (e.key === 'Escape') this.handleClose();
                },
            };
        },
        methods  : {
            selectLanguage(iso) {
                this.$store.commit('Account/updateInterfaceIso', iso);
                this.handleClose();
            },
            getAdditionalItemClass(iso) {
                if (this.userHasSelected) {
                    return this.interfaceIso === iso
                        ? 'languageSelector__item--selected'
                        : 'languageSelector__item--unselected';
                }
            },
            handleClose    : function () {
                this.$emit('close');
            },
            initOverlay    : function () {
                document.addEventListener('keydown', this.onEscape);
                document.body.classList.add('app--overlayActive');
            },
            teardownOverlay: function () {
                document.removeEventListener('keydown', this.onEscape);
                document.body.classList.remove('app--overlayActive');
            },
        },
        computed : {
            ...mapGetters({
                interfaceLanguages: 'Language/getInterfaceLanguages',
                interfaceIso      : 'Account/getInterfaceIso',
            }),
            userHasSelected: function () {
                return null !== this.selectedIso;
            },
            userUuid       : function () {
                return this.$store.getters['Account/getUuid'];
            },
        },
        created  : function () {
            this.initOverlay();
        },
        destroyed: function () {
            this.teardownOverlay();
        },
    };
</script>
