<template>
    <div>
        <div class="HamburgerSubMenu z-50">
            <div class="HamburgerSubMenu__container" @click="handleClose">
                <div class="HamburgerSubMenu__linkContainer">
                    <router-link class="HamburgerSubMenu__link container" :to="{name:'home'}">
                        {{ $t('CORE.HOME') }}
                    </router-link>
                </div>
                <div class="HamburgerSubMenu__linkContainer" v-show="!userIsLoggedIn">
                    <router-link class="HamburgerSubMenu__link container" :to="{name:'auth.register'}">
                        {{ $t('CORE.SIGN_UP') }}
                    </router-link>
                </div>
                <div class="HamburgerSubMenu__linkContainer" v-show="userIsLoggedIn">
                    <router-link class="HamburgerSubMenu__link container" :to="{name:'app.user.account.edit'}">
                        {{ $t('CORE.MY_ACCOUNT') }}
                    </router-link>
                </div>
                <div class="HamburgerSubMenu__linkContainer" v-show="!userIsLoggedIn">
                    <router-link class="HamburgerSubMenu__link container" :to="{name:'auth.login'}">
                        {{ $t('CORE.LOGIN') }}
                    </router-link>
                </div>
                <div class="HamburgerSubMenu__linkContainer" v-show="userIsLoggedIn">
                    <a class="HamburgerSubMenu__link container" href="#" @click="logout">
                        {{ $t('CORE.LOGOUT') }}
                    </a>
                </div>
            </div>
        </div>
        <div class="overlay" @click="handleClose"/>
    </div>
</template>

<script>
    import AuthService from '@/services/Auth/AuthService';

    export default {
        name   : 'HamburgerSubMenu',
        data   : function () {
            return {
                onEscape: e => {
                    if (e.key === 'Escape') this.handleClose();
                },
            };
        },
        props  : {
            userIsLoggedIn: {
                type   : Boolean,
                default: false,
            },
            isVisible     : {
                type   : Boolean,
                default: false,
            },
        },
        methods: {
            handleClose    : function () {
                this.$emit('close');
            },
            logout         : function () {
                AuthService.logoutUser()
                    .then(() => {
                        this.$router.push({name: 'auth.login'});
                    });
            },
            initOverlay    : function () {
                document.addEventListener('keydown', this.onEscape);
                document.body.classList.add('app--overlayActive');
            },
            teardownOverlay: function () {
                document.removeEventListener('keydown', this.onEscape);
                document.body.classList.remove('app--overlayActive');
            },
        },
        watch  : {
            isVisible: function (value) {
                true === value
                    ? this.initOverlay()
                    : this.teardownOverlay();
            },
        },
    };
</script>
